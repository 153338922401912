import $ from "jquery";

$(document).on("change", ".project_select", function (event) {
  var flag = false;
  var selected = $(this).val();
  var collection_selects = $(this)
    .parent()
    .parent()
    .parent()
    .parent()
    .find(".project_select")
    .not($(this));
  $(collection_selects).each(function (index, val) {
    if (selected == $(val).val()) {
      alert("すでに選択されています");
      flag = true;
    }
  });
  if (flag) {
    $(this).val("");
  }
});

$(document).on("change", ".user_select", function (event) {
  var flag = false;
  var selected = $(this).val();
  var collection_selects = $(this)
    .parent()
    .parent()
    .parent()
    .parent()
    .find(".user_select")
    .not($(this));
  $(collection_selects).each(function (index, val) {
    if (selected === $(val).val()) {
      alert("すでに選択されています");
      flag = true;
    }
  });
  if (flag) {
    $(this).val("");
  }
});

$(function () {
  $(".js-searchable").select2({
    dropdownAutoWidth: true,
    width: "auto",
  });

  $(".js-searchable-width-100").select2({
    dropdownAutoWidth: true,
    width: "100%",
  });

  $(".js-searchable-width-80").select2({
    dropdownAutoWidth: true,
    width: "80%",
  });
  $("form").on("cocoon:after-insert", function () {
    $(".js-searchable-width-80").select2({
      dropdownAutoWidth: true,
      width: "80%",
    });
  });
});
